import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = (props) => {
    const {
        label, customStyle = '', hasRoute = false, route = '', isButtonPrimary = true, handleOnClick, icon = '', ariaLabel = '', disabled = false, isTertiary = false
    } = props;
    const buttonStyle = isTertiary ? `neo-btn neo-btn-tertiary ${icon} ${customStyle}` : isButtonPrimary ? `neo-btn neo-btn-primary neo-btn-primary--default ${icon} ${customStyle}`
        : `neo-btn neo-btn-secondary neo-btn-secondary--default ${icon} ${customStyle}`;
    return hasRoute ? (
        <Link to={route}>
            <button
                type="button"
                className={`${buttonStyle}`}
                aria-label={ariaLabel}
                disabled={disabled}
            >
                {label}
            </button>
        </Link>
    ) : (
        <button
            onClick={handleOnClick}
            type="button"
            className={`${buttonStyle}`}
            aria-label={ariaLabel}
            disabled={disabled}
            >
            {label}
        </button>
    );
};

Button.propTypes = {
    label: PropTypes.string,
    customStyle: PropTypes.PropTypes.string,
    hasRoute: PropTypes.bool,
    route: PropTypes.string,
    isButtonPrimary: PropTypes.bool,
    handleOnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    ariaLabel: PropTypes.string,
    isTertiary: PropTypes.bool
};

export default Button;
