import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router';
import { Slide, ToastContainer } from 'react-toastify';

import Landing from '../Landing';
import Home from '../Home';
import NavigationBar from '../NavigationBar';
import PrivateRoute from '../../CommonComponents/PrivateRoute';
import { fetchUserInfo, logoutUser } from '../ActiveView/action';
import OAuthRedirectHandler from '../../CommonComponents/OAuthRedirectHandler';
import Spinner from '../../CommonComponents/Spinner';

import { AUTH, AUTH_CODE } from '../../constants';

import './style.css';

const Dashboard = () => {
    const dispatch = useDispatch();
    const { isFetchingUserDetails } = useSelector(state => state.activeView);
    useEffect(() => {
        if (localStorage.getItem(AUTH_CODE)) {
            dispatch(fetchUserInfo(true));
        }
    }, []);

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    const isLoggedIn = JSON.parse(localStorage.getItem(AUTH));

    return (
        <>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                transition={Slide}
                role="Notification"
                theme="colored"
            />
            {isFetchingUserDetails ? <Spinner /> : <div className="dashboardContainer">
            <NavigationBar authenticated={isLoggedIn} handleLogout={handleLogout} />
            <Switch>
                <Route exact path="/" render={props => isLoggedIn
                    ? <Redirect to={{ pathname: '/home/customers', state: { from: props.location } }} />
                    : <Landing authenticated={isLoggedIn} {...props} />} />
                <PrivateRoute path="/home" authenticated={isLoggedIn} component={Home} />
                <Route path="/oauth2/redirect" component={OAuthRedirectHandler} />
                <Route>
                    <Redirect to="/" />
                </Route>
            </Switch>
        </div>}
        </>);
};

Dashboard.propTypes = {
    authenticated: PropTypes.bool,
    location: PropTypes.object
};

export default Dashboard;
