import { combineReducers } from 'redux';
import ActiveView from '../Containers/ActiveView/reducer';
import Customers from '../Components/Customers/reducer';
import Subscription from '../Components/Subscriptions/reducer';

const rootReducer = combineReducers({
    activeView: ActiveView,
    customers: Customers,
    subscription: Subscription
});

export default rootReducer;
