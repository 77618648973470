import React from 'react';
import { AWS_AUTH_URL } from '../../constants';
import Button from '../../CommonComponents/Button';
import landingImage from '../../assets/images/landingPageImage.svg';

import './style.css';

const Login = () => <div className="landingContainer">
        <div style={{ backgroundImage: `url(${landingImage})`, backgroundSize: 'cover' }}></div>
        <div className="contentContainer">
            <div className="messageContainer">
                <h4>Welcome to</h4>
                <h4>Avaya Subscription Control</h4>
            </div>
            <a href={AWS_AUTH_URL} aria-label="Sign In" >
                <Button label="Sign In" isButtonPrimary={true} />
            </a>
        </div>
    </div>;

export default Login;
