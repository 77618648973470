import React from 'react';
import PropTypes from 'prop-types';

const LabelInput = (props) => {
    const {
        onInputChange, label, placeholder, inputValue, required, errorMessage
    } = props;
    return (
        <>
            <label htmlFor="inputField">{label} {required && <span style={{ color: 'red' }}>&nbsp;{`${required && '*'}`}</span>}</label>
            <input
                id="inputField"
                value={inputValue || ''}
                className="neo-input neo-input-required"
                placeholder={placeholder}
                onChange={onInputChange}
                required={required}
            />
            {inputValue && inputValue.trim() === '' && <div style={{ color: 'red' }} className="neo-input-hint" id="addon-on-the-left-hint">{errorMessage}</div>}
        </>
    );
};

LabelInput.propTypes = {
    onInputChange: PropTypes.func,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    inputValue: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string
};

export default LabelInput;
