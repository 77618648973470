import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
const Selectbox = (props) => {
    const {
        options, onSelect, selected, asteriskRequired = false, labelrequired = false, label
    } = props;
    return (
        <div className="neo-form">
            <div className="neo-form-control formWidth">
                <div className="neo-input-group">
                    {labelrequired && <label htmlFor="inputField">{label}<span style={{ color: 'red' }}>&nbsp;{`${asteriskRequired ? '*' : ''}`}</span></label>}
                    <div className="neo-select">
                        <select
                            className="neo-icon-chevron-down"
                            id="color1"
                            aria-describedby="select-hint"
                            value={selected}
                            onChange={onSelect}
                        >
                            {options.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.text}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

Selectbox.propTypes = {
    options: PropTypes.array,
    onSelect: PropTypes.func,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    asteriskRequired: PropTypes.bool,
    labelrequired: PropTypes.bool,
    label: PropTypes.string
};

export default Selectbox;
