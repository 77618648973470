import axios from 'axios';
import { ACCESS_TOKEN, baseUrl } from './constants';
// import keys from './config/keys';
import { logoutUser } from './Containers/ActiveView/action';
import store from './store';

const defaultOptions = {
    baseURL: baseUrl,
    headers: {
        'content-type': 'application/json'
    },
    responseType: 'json'
};

let instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
    const token = localStorage.getItem(ACCESS_TOKEN);
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

// This is refresh token implementation kept for future use
/*
 * const getAwsCongnitoRefreshToken = () => {
 *     var urlencoded = new URLSearchParams();
 *     urlencoded.append('client_id', keys.clientId);
 *     urlencoded.append('grant_type', 'refresh_token');
 *     urlencoded.append('refresh_token', `${localStorage.getItem('refresh_token')}`);
 *     urlencoded.append('Authorization', `Basic ${localStorage.getItem(AUTH_CODE)}`);
 *     const headers = {
 *         'Content-Type': 'application/x-www-form-urlencoded'
 *     };
 *     return axios.post(`${keys.authBaseUrl}/oauth2/token`, urlencoded, { headers });
 * };
 */

instance.interceptors.response.use(
    config => config,
    (error) => {
        console.log(error.response);
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            // getAwsCongnitoRefreshToken();
            store.dispatch(logoutUser());
        }
        return Promise.reject(error);
    }
);

export default instance;
