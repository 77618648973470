import React from 'react';
import PropTypes from 'prop-types';
import IconButton from './IconButton';

const Sheet = ({
    header, onCloseClick, children, message
}) => (
    <div className="neo-sheet sheet--custom sheetContainer" role="dialog" aria-labelledby="idOfHeader">
        <div className="neo-sheet__header bottomMargin">
        <div className="neo-sheet__header--left">
            <h4 id="heading">{header}</h4>
        </div>
        <div className="neo-sheet__header--right">
            <IconButton handleOnClick={onCloseClick} buttonStyle="neo-close neo-close--clear" ariaLabel={header} />
        </div>
        </div>
        <div className="sheetContent">
          <div className="sheetMessage bottomMargin">
              <p id="message">{message}</p>
          </div>
          {children}
        </div>
    </div>
);

Sheet.propTypes = {
    header: PropTypes.string,
    onCloseClick: PropTypes.func,
    children: PropTypes.node,
    message: PropTypes.string
};

export default Sheet;
