import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { AUTH_CODE } from '../constants';
import { getUrlParameter } from '../utils';

const OAuthRedirectHandler = ({ location }) => {
    const token = getUrlParameter('code', location.search);
    const error = getUrlParameter('error', location.hash);
    const handleOauth = () => {
        if (token) {
            localStorage.setItem(AUTH_CODE, token);
            return <Redirect to={{
                pathname: '/',
                state: { from: location }
            }}/>;
        } else {
            return <Redirect to={{
                pathname: '/',
                state: {
                    from: location,
                    error: error
                }
            }}/>;
        }
    };

    return handleOauth();
};

OAuthRedirectHandler.propTypes = {
    location: PropTypes.object
};

export default OAuthRedirectHandler;
