import { SET_DELETE_MODAL_STATUS, SET_SUBSCRIPTION_PROPERTIES, IS_FETCHING_SUBSCRIPTION_PROPERTIES, SET_EXPORT_MODAL_STATUS, EXPORT_SUBSCRIPTIONS, REFRESH_LIST, FETCH_SUBSCRIPTION_DETAIL, IS_UPLOADING_IN_PROGRESS, SET_SUBSCRIPTION_LIST, UPDATE_SUBSCRIPTION, SET_SUBSCRIPTION_MODAL_STATUS, SET_CURRENT_SUBSCRIPTION, IS_FETCHING_SUBSCRIPTION_LIST } from './actionTypes';

export const setModalStatus = payload => ({
    type: SET_DELETE_MODAL_STATUS,
    payload
});

export const setExportModalStatus = payload => ({
    type: SET_EXPORT_MODAL_STATUS,
    payload
});

export const setSubscriptionList = payload => ({
    type: SET_SUBSCRIPTION_LIST,
    payload
});

export const setSubscriptionModalStatus = payload => ({
    type: SET_SUBSCRIPTION_MODAL_STATUS,
    payload
});

export const setCurrentSubscription = payload => ({
    type: SET_CURRENT_SUBSCRIPTION,
    payload
});

export const fetchSubscriptionList = payload => ({
    type: IS_FETCHING_SUBSCRIPTION_LIST,
    payload
});

export const fetchSubscriptionDetail = payload => ({
    type: FETCH_SUBSCRIPTION_DETAIL,
    payload
});

export const updateSubscription = payload => ({
    type: UPDATE_SUBSCRIPTION,
    payload
});

export const exportSubscriptions = payload => ({
    type: EXPORT_SUBSCRIPTIONS,
    payload
});

export const setSubscriptionProperties = payload => ({
    type: SET_SUBSCRIPTION_PROPERTIES,
    payload
});

export const fetchSubscriptionProperties = payload => ({
    type: IS_FETCHING_SUBSCRIPTION_PROPERTIES,
    payload
});

export const setUploadProgress = payload => ({
    type: IS_UPLOADING_IN_PROGRESS,
    payload
});

export const refreshList = payload => ({
    type: REFRESH_LIST,
    payload
});
