import { SET_SPINNER_STATUS, IS_FETCHING_USER_INFO, SET_USER_INFO, IS_AUTHENTICATED, SET_FETCH_USER_ERROR } from './actionTypes';

const initialState = {
    showSpinner: false,
    isFetchingUserDetails: false,
    userInfo: {},
    authenticated: false,
    userInfoError: ''
};

export default (
    state = initialState,
    action
) => {
    switch (action.type) {
        case SET_SPINNER_STATUS:
            return {
                ...state,
                showSpinner: action.payload
            };
        case IS_FETCHING_USER_INFO:
            return {
                ...state,
                isFetchingUserDetails: action.payload
            };
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: action.payload,
                isFetchingUserDetails: false
            };
        case IS_AUTHENTICATED:
            return {
                ...state,
                authenticated: action.payload
            };
        case SET_FETCH_USER_ERROR:
            return {
                ...state,
                userInfoError: action.payload,
                isFetchingUserDetails: false,
                userInfo: {},
                authenticated: false
            };
        default:
            return {
                ...state
            };
    }
};
