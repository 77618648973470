import { SET_MODAL_STATUS, SET_SHOW_CUSTOMER_DETAILS,
    SET_SELECTED_CUSTOMER, SET_CUSTOMERS_LIST,
    SET_IS_FETCHING_CUSTOMERS } from './actionTypes';

const initialState = {
    customers: [],
    showModal: false,
    selectedCustomer: {},
    selectedCustomerId: null,
    showCustomerDetails: false,
    isFetchingCustomers: false
};

export default (
    state = initialState,
    action
) => {
    switch (action.type) {
        case SET_CUSTOMERS_LIST:
            return {
                ...state,
                customers: action.payload,
                isFetchingCustomers: false
            };
        case SET_IS_FETCHING_CUSTOMERS:
            return {
                ...state,
                isFetchingCustomers: action.payload
            };
        case SET_MODAL_STATUS:
            return {
                ...state,
                showModal: action.payload
            };
        case SET_SELECTED_CUSTOMER:
            return {
                ...state,
                selectedCustomer: action.payload.data,
                showCustomerDetails: action.payload.status,
                selectedCustomerId: action.payload.id
            };
        case SET_SHOW_CUSTOMER_DETAILS:
            return {
                ...state,
                selectedCustomerId: action.payload.id
            };
        default:
            return {
                ...state
            };
    }
};
