import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Customers from '../../Components/Customers';
import Subscriptions from '../../Components/Subscriptions';
import Spinner from '../../CommonComponents/Spinner';
import PrivateRoute from '../../CommonComponents/PrivateRoute';
import './style.css';

import { AUTH } from '../../constants';

const ActiveView = () => {
    const { showSpinner } = useSelector(state => state.activeView);
    const isLoggedIn = JSON.parse(localStorage.getItem(AUTH));
    return (
        <div className="activeViewContainer">
            <Switch>
                <PrivateRoute exact path={'/home/customers'} authenticated={isLoggedIn} component={Customers} />
                <PrivateRoute exact path={'/home/subscriptions'} authenticated={isLoggedIn} component={Subscriptions} />
            </Switch>
            {showSpinner && <Spinner size="xlarge" />}
        </div>
    );
};

ActiveView.propTypes = {
    location: PropTypes.object,
    authenticated: PropTypes.bool
};

export default ActiveView;
