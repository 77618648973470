import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import LabelInput from '../../CommonComponents/LabelInput';
import Label from '../../CommonComponents/Label';
import Button from '../../CommonComponents/Button';
import { updateSubscription } from './action';
import { useDispatch } from 'react-redux';
import DateTimePicker from '../../CommonComponents/DateTimePicker';

const SubscriptionDetails = ({ rowData }) => {
    const dispatch = useDispatch();
    const [eProjectCode, setProjectCode] = useState('');
    const [solutionName, setProjectDesc] = useState('');
    const [endDate, setEndDate] = useState(new Date());
    useEffect(() => {
        if (rowData) {
            setProjectCode(rowData.eProjectCode);
            setProjectDesc(rowData.solutionName);
            setEndDate(new Date(rowData.endDate));
        }
    }, [rowData]);

    const updateSubscriptionData = () => {
        const subscriptionData = rowData;
        subscriptionData.eProjectCode = eProjectCode;
        subscriptionData.solutionName = solutionName;
        subscriptionData.endDate = moment(endDate).format('L');
        dispatch(updateSubscription(subscriptionData));
    };

    const isUpdateButtonEnabled = () => eProjectCode === rowData.eProjectCode && solutionName === rowData.solutionName && moment(rowData.endDate).format('L') === moment(endDate).format('L');

    return (
        <div className="neo-form">
            { rowData
                && <div>
                    <div className="neo-form-control">
                <div className="neo-input-group bottomMargin">
                <Label
                    label="Contract ID"
                    value={rowData.contract}
                    id="contactsSystemId"
                    labelStyle="lableStyleTwo"
                    customClasses="nameLabelStyle"
                />
                </div>
            </div>
            <div className="neo-form-control">
                <div className="neo-input-group bottomMargin">
                <Label
                    label="Customer"
                    value={rowData.customer}
                    id="contactsSystemId"
                    labelStyle="lableStyleTwo"
                    customClasses="nameLabelStyle"
                />
                </div>
            </div>
            <div className="neo-form-control">
                <div className="neo-input-group bottomMargin">
                <Label
                    label="Material Code"
                    value={rowData.materialCode}
                    id="contactsSystemId"
                    labelStyle="lableStyleTwo"
                    customClasses="nameLabelStyle"
                />
                </div>
            </div>
            <div className="neo-form-control">
                <div className="neo-input-group bottomMargin">
                <Label
                    label="Material Code Description"
                    value={rowData.materialDesc}
                    id="contactsSystemId"
                    labelStyle="lableStyleTwo"
                    customClasses="nameLabelStyle"
                />
                </div>
            </div>
            <div className="neo-form-control">
                <div className="neo-input-group bottomMargin">
                <Label
                    label="Start Date"
                    value={rowData.startDate}
                    id="contactsSystemId"
                    labelStyle="lableStyleTwo"
                    customClasses="nameLabelStyle"
                />
                </div>
            </div>
            <div className="neo-form-control">
                <div className="neo-input-group bottomMargin">
                <DateTimePicker label="End Date" value={endDate} handleDateTimeChange={date => setEndDate(date)} />
                </div>
            </div>
            <div className="neo-form-control">
                <div className="neo-input-group bottomMargin">
                    <LabelInput
                        inputValue={eProjectCode}
                        label="eProject Code"
                        placeholder="Enter eProject Code"
                        onInputChange={nameObj => setProjectCode(nameObj.target.value)}
                        required={false}
                        errorMessage="Please Enter Project Code"
                    />
                </div>
                <div className="neo-input-group bottomMargin">
                    <LabelInput
                        inputValue={solutionName}
                        label="Solution Name"
                        placeholder="Enter Description"
                        onInputChange={nameObj => setProjectDesc(nameObj.target.value)}
                        required={false}
                        errorMessage="Please Enter Solution Name"
                    />
                </div>
                <Button
                    label="Update"
                    customStyle="marginTop floatRight"
                    isButtonPrimary={true}
                    ariaLabel="Save"
                    handleOnClick={updateSubscriptionData}
                    disabled={ isUpdateButtonEnabled() }
                />
            </div>
                </div>
            }
        </div>
    );
};

SubscriptionDetails.propTypes = {
    rowData: PropTypes.object
};

export default SubscriptionDetails;
