import { all, fork, call } from 'redux-saga/effects';
import customersSaga from '../Components/Customers/saga';
import mainSaga from '../Containers/ActiveView/saga';
import { showToast } from '../utils';
import subscriptionSaga from '../Components/Subscriptions/saga';

export const rootSaga = function* () {
    try {
        yield all([
            fork(customersSaga), fork(mainSaga), fork(subscriptionSaga)
        ]);
    } catch (error) {
        const errorMessage = error.response ? error.response.data.data : error.message;
        yield call(showToast, 'error', errorMessage);
    }
};
