import React from 'react';
import PropTypes from 'prop-types';

const IconButton = (props) => {
    const {
        handleOnClick, buttonStyle, ariaLabel
    } = props;
    return (
        <button
            onClick={handleOnClick}
            type="button"
            aria-label={ariaLabel}
            className={buttonStyle}
            tabIndex={-1}
        />
    );
};

IconButton.propTypes = {
    handleOnClick: PropTypes.func,
    buttonStyle: PropTypes.string,
    ariaLabel: PropTypes.string
};

export default IconButton;
