import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './style.css';

const DateTimePicker = (props) => {
    const {
        value, label, required = false, handleDateTimeChange, showTimeSelect = false
    } = props;
    return (
        <div>
            <div className="neo-input-group">
                <label htmlFor="inputField">
                    {label}
                    <span style={{ color: 'red' }}>
                        &nbsp;{`${required ? '*' : ''}`}
                    </span>
                </label>
                <DatePicker
                    selected={value}
                    onChange={date => handleDateTimeChange(date)}
                    showTimeSelect={showTimeSelect}
                    customInput={
                        <input
                            className="neo-input neo-input-required"
                        />
                    }
                />
            </div>
        </div>
    );
};

DateTimePicker.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    showTimeSelect: PropTypes.bool,
    handleDateTimeChange: PropTypes.func
};

export default DateTimePicker;
