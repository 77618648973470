import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Login from './Login.js';

const Landing = (props) => {
    useEffect(() => {
        if (props.location.state && props.location.state.error) {
            setTimeout(() => {
                props.history.replace({
                    pathname: props.location.pathname,
                    state: {}
                });
            }, 100);
        }
    });

    return (
        props.authenticated ? <Redirect to={{
            pathname: '/home',
            state: { from: props.location }
          }}
        /> : <Login />
    );
};

Landing.propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
    authenticated: PropTypes.bool
};

export default Login;
