import myAxios from '../../authAxios';
import {
    all,
    call,
    put,
    takeLatest,
    select
} from 'redux-saga/effects';
import { showToast } from '../../utils';

import { baseUrl } from '../../constants';

import { setCustomersList, setSelectedCustomer } from './action';
import { setSpinnerStatus } from '../../Containers/ActiveView/action';
import { SET_IS_FETCHING_CUSTOMERS, SET_SHOW_CUSTOMER_DETAILS, UPDATE_CUSTOMER_DETAILS } from './actionTypes';

const fetchCustomers = () => myAxios.get(`${baseUrl}/subscriptions/customers`);
const updateCustomer = payload => myAxios.put(`${baseUrl}/subscriptions/customers`, payload);
const viewCustomer = id => myAxios.get(`${baseUrl}/subscriptions/customers/${id}`);

const handleFetchCustomerDetails = function* ({ payload }) {
    try {
        yield put(setSpinnerStatus(true));
        const response = yield call(viewCustomer, payload.id);
        yield put(setSelectedCustomer({
            data: response.data, status: true, id: payload.id
        }));
        yield put(setSpinnerStatus(false));
    } catch (error) {
        const errorMessage = error.response ? error.response.data.data : error.message;
        yield put(setSpinnerStatus(false));
        yield call(showToast, 'error', errorMessage);
    }
};

const handleUpdateCustomer = function* (data) {
    try {
        yield put(setSpinnerStatus(true));
        yield call(updateCustomer, data.payload);
        const { customers } = yield select();
        yield put(setCustomersList(customers.customers.map(customer => data.payload.id === customer.id ? { ...customer, region: data.payload.region } : customer)));
        yield put(setSpinnerStatus(false));
        yield call(showToast, 'success', 'Customer Details Updated Successfully.');
    } catch (error) {
        yield call(showToast, 'error', error.response ? error.response.data.data : error.message);
        yield put(setSpinnerStatus(false));
    }
};

const handleFetchCustomers = function* () {
    try {
        yield put(setSpinnerStatus(true));
        const response = yield call(fetchCustomers);
        yield put(setCustomersList(response.data));
        yield put(setSpinnerStatus(false));
    } catch (error) {
        const errorMessage = error.response ? error.response.data : error.message;
        yield put(setSpinnerStatus(false));
        yield call(showToast, 'error', errorMessage);
    }
};

const customersSaga = function* (...args) {
    try {
        yield all([
            takeLatest(SET_IS_FETCHING_CUSTOMERS, handleFetchCustomers),
            takeLatest(SET_SHOW_CUSTOMER_DETAILS, handleFetchCustomerDetails),
            takeLatest(UPDATE_CUSTOMER_DETAILS, handleUpdateCustomer, ...args)
        ]);
    } catch (error) {
        yield call(showToast, 'error', error.response ? error.response.data : error.message);
    }
};

export default customersSaga;
