import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import LeftNavigation from '../LeftNavigation';
import ActiveView from '../ActiveView';

const Home = ({ location }) => {
    useEffect(() => {
    });
    return <div className="bodyContainer">
            <LeftNavigation location={location} />
            <ActiveView />
        </div>;
};

Home.propTypes = {
    authenticated: PropTypes.bool,
    location: PropTypes.object
};

export default Home;
