import React from 'react';
import PropTypes from 'prop-types';

const Label = ({
    label, value, id, labelStyle, customClasses
}) => (
    <label
        className={labelStyle}
        htmlFor={id}
        >
    {label}
    <br />
    <span
        id={id}
        aria-label={label}
        className= {`spanStyle ${customClasses}`}
    >
        {value}
    </span>
</label>
);

Label.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    labelStyle: PropTypes.string,
    customClasses: PropTypes.string
};

export default Label;
