import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import UploadButton from '../CommonComponents/UploadButton';

const Breadcrumb = ({
    currentLinkName,
    linkList,
    // isFileAttached,
    isSaveDisabled = false,
    // isUploadRequired = false,
    cancelButtonClicked,
    // handleRemoveFile,
    saveButtonClicked,
    isSaveRequired = false,
    // handleUploadNewFile
    isCancelRequired = false
}) => (
    <>
            <nav aria-label="Breadcrumb" className="neo-breadcrumbs">
                <ol>
                    {linkList.map(linkItem => (
                            <li
                                key={linkItem.linkName}
                                className="neo-breadcrumbs__link"
                            >
                                <Link to={`${linkItem.routerLink}`}>
                                    {linkItem.linkName}
                                </Link>
                            </li>
                        ))}
                    <li className="neo-breadcrumbs__link neo-breadcrumbs__link--current">
                        <a href="./index.html" aria-current="page">
                            {currentLinkName}
                        </a>
                    </li>
                </ol>
                <div className="neo-breadcrumbs__actions">
                    {isCancelRequired && <button
                        className="neo-btn neo-btn-secondary neo-btn-secondary--default"
                        type="button"
                        onClick={cancelButtonClicked}
                    >
                        Cancel
                    </button>}
                    {isSaveRequired && <button
                        className="neo-btn neo-btn-primary neo-btn-primary--default"
                        type="button"
                        disabled={isSaveDisabled}
                        onClick={saveButtonClicked}
                    >
                        Save
                    </button>}
                    {/* {isUploadRequired
                        && <UploadButton
                                label="Browse File"
                                handleClick={handleUploadNewFile}
                                handleCloseAction={handleRemoveFile}
                                showFileNamePill={isFileAttached}
                            />} */}
                </div>
            </nav>
    </>
);

Breadcrumb.propTypes = {
    currentLinkName: PropTypes.string,
    linkList: PropTypes.array,
    isSaveDisabled: PropTypes.bool,
    isFileAttached: PropTypes.bool,
    isUploadRequired: PropTypes.bool,
    cancelButtonClicked: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
    saveButtonClicked: PropTypes.oneOfType([PropTypes.bool, PropTypes.any]),
    // handleUploadNewFile: PropTypes.func,
    isSaveRequired: PropTypes.bool,
    // handleRemoveFile: PropTypes.func
    isCancelRequired: PropTypes.bool
};

export default Breadcrumb;
