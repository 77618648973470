export const SET_DELETE_MODAL_STATUS = 'SET_DELETE_MODAL_STATUS';
export const SET_SUBSCRIPTION_MODAL_STATUS = 'SET_SUBSCRIPTION_MODAL_STATUS';
export const SET_CURRENT_SUBSCRIPTION = 'SET_CURRENT_SUBSCRIPTION';
export const IS_FETCHING_SUBSCRIPTION_LIST = 'IS_FETCHING_SUBSCRIPTION_LIST';
export const FETCH_SUBSCRIPTION_DETAIL = 'FETCH_SUBSCRIPTION_DETAIL';
export const SET_SUBSCRIPTION_LIST = 'SET_SUBSCRIPTION_LIST';
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION';
export const IS_UPLOADING_IN_PROGRESS = 'IS_UPLOADING_IN_PROGRESS';
export const REFRESH_LIST = 'REFRESH_LIST';
export const EXPORT_SUBSCRIPTIONS = 'EXPORT_SUBSCRIPTIONS';
export const SET_EXPORT_MODAL_STATUS = 'SET_EXPORT_MODAL_STATUS';
export const IS_FETCHING_SUBSCRIPTION_PROPERTIES = 'IS_FETCHING_SUBSCRIPTION_PROPERTIES';
export const SET_SUBSCRIPTION_PROPERTIES = 'SET_SUBSCRIPTION_PROPERTIES';
