import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './style.css';

const LeftNavigation = ({ location }) => {
    const [active, setActive] = useState('customers');
    useEffect(() => {
        console.log(location);
        if (location.pathname.includes('subscriptions')) {
            setActive('subscriptions');
        }
    }, []);

    return (
        <div className="neo-leftnav--wrapper leftNavigationContainer">
            <nav className="neo-leftnav" aria-label="secondary">
                <ul className="neo-leftnav__nav">
                    <li className={`neo-leftnav__main ${active === 'customers' ? 'neo-leftnav__main--active' : ''}`}>
                        <Link to="/home/customers" tabIndex={0} className="neo-icon-contact" onClick={() => setActive('customers')}>Customers</Link>
                    </li>
                    <li className={`neo-leftnav__main ${active === 'subscriptions' ? 'neo-leftnav__main--active' : ''}`}>
                        <Link to="/home/subscriptions" tabIndex={0} className="neo-icon-sub-accounts" onClick={() => setActive('subscriptions')}>Subscriptions</Link>
                    </li>
                </ul>
            </nav>
        </div>
    );
};

LeftNavigation.propTypes = {
    location: PropTypes.object
};

export default LeftNavigation;
