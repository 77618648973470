import axios from 'axios';
import {
    all,
    call,
    put,
    takeLatest
} from 'redux-saga/effects';
import { showToast } from '../../utils';
import keys from '../../config/keys';
import { ACCESS_TOKEN, AUTH, AUTH_CODE, REFRESH_TOKEN } from '../../constants';

import { setIsAutenticated, setUserInfo, setUserInfoError } from './action';
import { IS_FETCHING_USER_INFO, LOGOUT_USER } from './actionTypes';

const logoutUser = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('client_id', keys.clientId);
    urlencoded.append('token', localStorage.getItem(REFRESH_TOKEN));

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    axios.post(`${keys.authBaseUrl}/oauth2/revoke`, urlencoded, { headers });
    window.location = `${keys.authBaseUrl}/logout?client_id=${keys.clientId}&response_type=code&logout_uri=${keys.clientAppUri}`;
};

const getAwsCongnitoAccessToken = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append('client_id', keys.clientId);
    urlencoded.append('grant_type', 'authorization_code');
    urlencoded.append('code', localStorage.getItem(AUTH_CODE));
    urlencoded.append('redirect_uri', keys.redirectUri);

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
    };

    return axios.post(`${keys.authBaseUrl}/oauth2/token`, urlencoded, { headers });
};

const fetchUserDetails = () => {
    const headers = {
        'Content-Type': 'application/x-amz-json-1.1',
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser'
    };

    const body = {
        AccessToken: `${localStorage.getItem(ACCESS_TOKEN)}`
    };
    return axios.post(`https://cognito-idp.us-west-2.amazonaws.com`, body, { headers });
};

const handleFetchUserDetails = function* () {
    try {
        const data = yield call(getAwsCongnitoAccessToken);
        localStorage.setItem(ACCESS_TOKEN, data.data.access_token);
        localStorage.setItem(REFRESH_TOKEN, data.data.refresh_token);
    } catch (error) {
        const errorMessage = error.response ? error.response.data?.error || error.response.data : error.message;
        if (errorMessage !== 'invalid_grant') {
            // Refresh token has been revoked. Authorization code has been consumed already or does not exist.
            yield call(showToast, 'error', errorMessage);
        }
    }

    try {
        const response = yield call(fetchUserDetails);
        const emailAttribute = response.data.UserAttributes.find(item => item.Name === 'email');
        const nameAttribute = response.data.UserAttributes.find(item => item.Name === 'name');
        yield put(setUserInfo({
            username: response.data.Username, email: emailAttribute.Value, name: nameAttribute.Value
        }));
        localStorage.setItem(AUTH, JSON.stringify(true));
        yield put(setIsAutenticated(true));
    } catch (error) {
        const errorMessage = error.response ? error.response.data?.error || error.response.data : error.message;
        if (errorMessage?.__type === 'NotAuthorizedException') {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(AUTH_CODE);
            localStorage.removeItem(AUTH);
            yield put(setUserInfo({}));
            yield put(setIsAutenticated(false));
        }

        yield call(showToast, 'error', errorMessage);
        yield put(setUserInfoError(errorMessage));
    }
};

const handleLogoutUser = function* () {
    try {
        localStorage.removeItem(ACCESS_TOKEN);
        localStorage.removeItem(AUTH_CODE);
        localStorage.removeItem(AUTH);
        yield call(logoutUser);
    } catch (error) {
        console.log('Error - ActiveView saga >> handleLogoutUser', error);
    }
};

const mainSaga = function* () {
    try {
        yield all([
            takeLatest(IS_FETCHING_USER_INFO, handleFetchUserDetails),
            takeLatest(LOGOUT_USER, handleLogoutUser)
        ]);
    } catch (error) {
        yield call(showToast, 'error', error.response ? error.response.data : error.message);
    }
};

export default mainSaga;
