import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import SearchInput from './SearchInput';
import PaginationBar from './PaginationBar';
import IconButton from './IconButton';
import { Link } from 'react-router-dom';
import SelectBox from '../CommonComponents/Selectbox/index.js';

const TableHeader = ({
    columns, hasCheckbox = false, handleSelectAllCheckboxClick, isAllSelected
}) => (
        <thead>
            <tr>
                {hasCheckbox && <th className="check">
                    <label htmlFor="checkAll">
                    <input
                        className="neo-check"
                        type="checkbox"
                        id="checkAll"
                        role="checkbox"
                        aria-checked="false"
                        checked={isAllSelected}
                        onChange={() => handleSelectAllCheckboxClick(!isAllSelected)}
                    /><label htmlFor="checkAll"></label>
                    </label>
                </th>}
                <th> </th>
                {columns.map(column => column.show && <th key={`${column.key}`}>{column.header}</th>)}
            </tr>
        </thead>
);

TableHeader.propTypes = {
    columns: PropTypes.array,
    hasCheckbox: PropTypes.bool,
    handleSelectAllCheckboxClick: PropTypes.func,
    isAllSelected: PropTypes.bool
};

const TableRows = ({
    data,
    columns,
    hasCheckbox = false,
    handleCheckboxClick,
    actions
}) => {
    let Actions = actions;
    return (<tbody>
            {data
                && data.map((row, index) => (
                    <tr key={`row-${index}`} className={`${row.checked ? 'active' : ''}`}>
                        {hasCheckbox && <td>
                            <input
                                className="neo-check"
                                type="checkbox"
                                id={`fullTableCheck1-${index}`}
                                role="checkbox"
                                aria-checked="false"
                                checked={row.checked}
                                onChange={() => handleCheckboxClick(row)}
                            /><label htmlFor={`fullTableCheck1-${index}`}></label>
                        </td>}
                        <td> </td>
                        {columns.map(column => column.key === 'actions'
                                ? <td key={column.header}>
                                {<Actions rowData={row} />}
                            </td>
                                : <td key={column.header}>
                                {column.hasLink ? <Link to={row.link}>{row[column.key]}</Link> : row[column.key]}
                            </td>)}
                    </tr>
                ))}
        </tbody>);
};

TableRows.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    moreOptions: PropTypes.array,
    hasCheckbox: PropTypes.bool,
    handleCheckboxClick: PropTypes.func,
    handleMenuButtonClick: PropTypes.func,
    actions: PropTypes.func
};

const Table = (props) => {
    const {
        handleSearchInputChange,
        searchQuery,
        resetSearch,
        placeholder,
        tableColumns,
        rowsData,
        paginationData,
        refreshTable,
        handleActionButtonClick,
        actionButtonLabel,
        actionButtonRoute,
        isPaginationRequired,
        isActionButtonRequired,
        isActionDropdownRequired,
        isSearchRequired,
        handlePageSizeChanged,
        handleSetCurrentPageNumber,
        hasCheckbox,
        handleCheckboxClick,
        handleSelectAllCheckboxClick,
        isAllSelected,
        isMultipleSelected,
        handleDisableActionbuttonClicked,
        actions,
        moreOptions,
        selectedOption,
        handleOptionSelect
    } = props;
    return (
        <div className="tableStickyContainer neo-table--sticky">
            <div className="neo-table__actions">
                {isActionButtonRequired && (
                    <div className="neo-table__actions--left">
                        <div className="neo-dropdown neo-dropdown--onhover">
                            <Button
                                handleOnClick={handleActionButtonClick}
                                label={actionButtonLabel}
                                hasRoute
                                route={actionButtonRoute}
                                isButtonPrimary={true}
                                ariaLabel={actionButtonLabel}
                            />
                        </div>
                    </div>
                )}
                {isActionDropdownRequired && (
                    <div className="neo-table__actions--left">
                        <div className="neo-dropdown neo-dropdown--onhover">
                            <SelectBox
                                options={moreOptions}
                                selected={selectedOption}
                                onSelect={handleOptionSelect}
                            />
                        </div>
                    </div>
                )}
                {hasCheckbox && isMultipleSelected && <div className="neo-table__actions--left">
                    <Button
                        label="Disable"
                        isButtonPrimary={false}
                        isTertiary={true}
                        customStyle="neo-btn-tertiary--warning"
                        icon="neo-icon-trash"
                        handleOnClick={handleDisableActionbuttonClicked}
                        actionButtonLabel="Add Atribute"
                    />
                </div>}
                {isSearchRequired && (
                    <div className="neo-table__actions--right">
                        <form className="neo-form">
                            <div className="neo-form-control neo-input-icon">
                                <div className="neo-input-group">
                                    <div className="neo-input-icon__wrapper neo-input-editable__wrapper">
                                        <span className="neo-icon-search" />
                                        <SearchInput
                                            onInputChange={
                                                handleSearchInputChange
                                            }
                                            searchQuery={searchQuery}
                                            placeholder={placeholder}
                                            resetSearch={resetSearch}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <IconButton
                            handleOnClick={refreshTable}
                            buttonStyle="neo-btn-square neo-btn-square-tertiary neo-btn-square-tertiary--info neo-icon-refresh"
                            ariaLabel="refresh"
                        />
                    </div>
                )}
            </div>
            <div className="tableContainer"><table className="neo-table">
                <TableHeader
                    columns={tableColumns}
                    hasCheckbox={hasCheckbox}
                    handleSelectAllCheckboxClick={handleSelectAllCheckboxClick}
                    isAllSelected={isAllSelected}
                />
                {rowsData.length ? <TableRows
                    columns={tableColumns}
                    data={rowsData}
                    hasCheckbox={hasCheckbox}
                    handleCheckboxClick={handleCheckboxClick}
                    actions={actions}
                /> : null}
            </table>
            {rowsData.length === 0 ? <div className="noDataFoundContainer">
                <h1>No data found!</h1>
            </div> : null}
            </div>
            <div className="paginationContainer">{isPaginationRequired && rowsData.length !== 0 && (
                <PaginationBar
                    pageNumber={paginationData.pageNumber}
                    totalCount={paginationData.totalCount}
                    pageSize={paginationData.pageSize}
                    currentPageList={paginationData.currentPageList}
                    pageSizeList={paginationData.pageSizeList}
                    pageSizeChanged={handlePageSizeChanged}
                    setCurrentPageNumber={handleSetCurrentPageNumber}
                />
            )}
            </div>
        </div>
    );
};

Table.propTypes = {
    handleSearchInputChange: PropTypes.func,
    handleOptionSelect: PropTypes.func,
    searchQuery: PropTypes.string,
    resetSearch: PropTypes.func,
    placeholder: PropTypes.string,
    tableColumns: PropTypes.array,
    moreOptions: PropTypes.array,
    rowsData: PropTypes.array,
    paginationData: PropTypes.object,
    selectedOption: PropTypes.number,
    refreshTable: PropTypes.func,
    handleActionButtonClick: PropTypes.func,
    actionButtonLabel: PropTypes.string,
    actionButtonRoute: PropTypes.string,
    isPaginationRequired: PropTypes.bool,
    isActionButtonRequired: PropTypes.bool,
    isActionDropdownRequired: PropTypes.bool,
    isSearchRequired: PropTypes.bool,
    handlePageSizeChanged: PropTypes.func,
    handleSetCurrentPageNumber: PropTypes.func,
    hasCheckbox: PropTypes.bool,
    handleCheckboxClick: PropTypes.func,
    handleSelectAllCheckboxClick: PropTypes.func,
    isAllSelected: PropTypes.bool,
    isMultipleSelected: PropTypes.bool,
    handleDisableActionbuttonClicked: PropTypes.func,
    handleMenuButtonClick: PropTypes.func,
    actions: PropTypes.func
};

export default Table;
