import React, { useState } from 'react';
import PropTypes from 'prop-types';

const UploadButton = ({
    label, handleClick, handleCloseAction, showFileNamePill
}) => {
    const [selectFile, setSelectedFile] = useState({});

    const handleFileSelectClick = (event) => {
        setSelectedFile(event.target.files[0]);
        handleClick(event.target.files[0]);
    };

    const handleCloseClick = () => {
        setSelectedFile({});
        handleCloseAction();
    };

    return (<div className="uploadButtonWrapper" style={{ marginTop: 'var(--margin-sixteen)' }}>
        {showFileNamePill ? <><div className="neo-chip neo-chip--default">{selectFile.name}</div><span onClick={handleCloseClick} className="neo-icon-close closeIcon" aria-label="remove selected file"></span></> : <>
            <input className="fileInput" name="browseFile" type="file" id={label} multiple={false} accept=".csv" onChange={handleFileSelectClick} />
            <button className="neo-btn neo-btn-secondary neo-btn-secondary--default" aria-label={label}>{label}</button>
        </>
        }
    </div>);
};

UploadButton.propTypes = {
    label: PropTypes.string,
    handleClick: PropTypes.func,
    handleCloseAction: PropTypes.func,
    showFileNamePill: PropTypes.bool
};

export default UploadButton;

