import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const Spinner = ({ size }) => {
    const spinnerSize = size === 'large' ? 'neo-spinner--large' : size === 'xlarge' ? 'neo-spinner--x-large' : '';
    return (
        <div className="spinnerContainer">
            <div className={`spinner neo-spinner neo-spinner--x-large ${spinnerSize}`}></div>
        </div>
    );
};

Spinner.propTypes = {
    size: PropTypes.string
};

export default Spinner;

