import { SET_MODAL_STATUS, SET_SELECTED_CUSTOMER,
    SET_SHOW_CUSTOMER_DETAILS, SET_CUSTOMERS_LIST,
    SET_IS_FETCHING_CUSTOMERS, UPDATE_CUSTOMER_DETAILS } from './actionTypes';

export const setModalStatus = payload => ({
    type: SET_MODAL_STATUS,
    payload
});

export const setCustomersList = payload => ({
    type: SET_CUSTOMERS_LIST,
    payload
});

export const setIsFetchingCustomers = payload => ({
    type: SET_IS_FETCHING_CUSTOMERS,
    payload
});

export const setSelectedCustomer = payload => ({
    type: SET_SELECTED_CUSTOMER,
    payload
});

export const setShowCustomerDetails = payload => ({
    type: SET_SHOW_CUSTOMER_DETAILS,
    payload
});

export const updateCustomerDetails = payload => ({
    type: UPDATE_CUSTOMER_DETAILS,
    payload
});
