import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({
    component: Component, authenticated, ...rest
}) => <Route
    {...rest}
    render={props => authenticated ? (
        <Component {...rest} {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location }
          }}
        />
      )
    }
  />;

PrivateRoute.propTypes = {
    component: PropTypes.elementType,
    authenticated: PropTypes.bool,
    location: PropTypes.object
};

export default PrivateRoute;
