import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as _ from 'lodash';

import Breadcrumb from '../../CommonComponents/Breadcrumb';
import Table from '../../CommonComponents/Table';
import IconButton from '../../CommonComponents/IconButton';
import Sheet from '../../CommonComponents/Sheet';
import CustomerDetails from './CustomerDetails';

import { setSelectedCustomer, setShowCustomerDetails, setIsFetchingCustomers } from './action';

import './style.css';

const columnsHeaders = [
    {
        key: 'fl',
        header: 'FL',
        show: true,
        hasLink: false
    },
    {
        key: 'name',
        header: 'Name',
        show: true,
        hasLink: false
    },
    {
        key: 'shipTo',
        header: 'Ship To',
        show: true,
        hasLink: false
    },
    {
        key: 'location',
        header: 'Location',
        show: true,
        hasLink: false
    },
    {
        key: 'region',
        header: 'Region',
        show: true,
        hasLink: false
    },
    {
        key: 'actions',
        header: 'Actions',
        show: true,
        hasLink: false
    }
];

const Actions = ({ rowData }) => {
    const dispatch = useDispatch();
    const handelOnViewClick = () => {
        const payload = {};
        payload.id = rowData.id;
        payload.status = true;
        dispatch(setShowCustomerDetails(payload));
    };

    return (
        <IconButton
            handleOnClick={handelOnViewClick}
            buttonStyle="neo-btn-circle neo-btn-circle-tertiary neo-btn-circle-tertiary--info neo-icon-view-on"
            ariaLabel={'view customer'} />
    );
};

Actions.propTypes = {
    rowData: PropTypes.object
};

const Customers = () => {
    const dispatch = useDispatch();
    const {
        selectedCustomer, showCustomerDetails, customers
    } = useSelector(state => state.customers);
    const linkList = [];
    useEffect(() => {
        dispatch(setIsFetchingCustomers(true));
    }, []);

    const handleCloseCustomerDetailsView = () => {
        dispatch(setSelectedCustomer({
            data: {}, status: false, id: null
        }));
    };
    return (
        <div className="customersContainer">
            <Breadcrumb
                linkList={linkList}
                currentLinkName="Customers"
            />
            <Table
                tableColumns={columnsHeaders}
                rowsData={customers}
                isSearchRequired={false}
                actions={Actions}
            />
            {showCustomerDetails && !_.isEmpty(selectedCustomer) && <Sheet
                header="Customer Details"
                onCloseClick={handleCloseCustomerDetailsView}
                message="Details of the customer"
                >
                    <CustomerDetails customer={selectedCustomer} />
                </Sheet>}
        </div>
    );
};

export default Customers;
