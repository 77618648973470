import React from 'react';
import PropTypes from 'prop-types';
import IconButton from './IconButton';

const SearchInput = (props) => {
    const {
        onInputChange, searchQuery, placeholder, resetSearch
    } = props;
    return (
        <>
            <input
                onChange={onInputChange}
                value={searchQuery}
                className="neo-input"
                placeholder={placeholder}
            />
            <IconButton
                handleOnClick={resetSearch}
                ariaLabel="clear search"
                buttonStyle="neo-input-editable__close neo-icon-end"
            />
        </>
    );
};

SearchInput.propTypes = {
    onInputChange: PropTypes.func,
    searchQuery: PropTypes.string,
    placeholder: PropTypes.string,
    resetSearch: PropTypes.func
};

export default SearchInput;
