import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Breadcrumb from '../../CommonComponents/Breadcrumb';
import Table from '../../CommonComponents/Table';
import IconButton from '../../CommonComponents/IconButton';
import Sheet from '../../CommonComponents/Sheet';
import UploadButton from '../../CommonComponents/UploadButton';
import SubscriptionDetails from './SubscriptionDetails';
import ExportSubscriptionModal from './ExportSubscriptionModal';
import Button from '../../CommonComponents/Button';

import {
    setExportModalStatus,
    setCurrentSubscription,
    fetchSubscriptionDetail,
    setSubscriptionModalStatus,
    fetchSubscriptionList,
    setUploadProgress
} from './action';

import './style.css';

const columnsHeaders = [
    {
        key: 'contract',
        header: 'Contract ID',
        show: true,
        hasLink: false
    },
    {
        key: 'customer',
        header: 'Customer',
        show: true,
        hasLink: false
    },
    {
        key: 'eProjectCode',
        header: 'eProject Code',
        show: true,
        hasLink: false
    },
    {
        key: 'solutionName',
        header: 'Solution Name',
        show: true,
        hasLink: false
    },
    {
        key: 'startDate',
        header: 'Start Date',
        show: true,
        hasLink: false
    },
    {
        key: 'endDate',
        header: 'End Date',
        show: true,
        hasLink: false
    },
    {
        key: 'actions',
        header: 'Actions',
        show: true,
        hasLink: false
    }
];

const Actions = ({ rowData }) => {
    const dispatch = useDispatch();
    const handelOnViewClick = () => {
        dispatch(fetchSubscriptionDetail(rowData.id));
        dispatch(setSubscriptionModalStatus(true));
    };

    return (
        <IconButton
            handleOnClick={handelOnViewClick}
            buttonStyle="neo-btn-circle neo-btn-circle-tertiary neo-btn-circle-tertiary--info neo-icon-view-on"
            ariaLabel={'view subscription'}
        />
    );
};

Actions.propTypes = {
    rowData: PropTypes.object
};

const Subscription = () => {
    const dispatch = useDispatch();
    const subscriptionStatusList = [
        { text: 'All', value: 'all' },
        { text: 'Active', value: 'active' },
        { text: 'Expire Within 3 Month', value: 'expire' },
        { text: 'Inactive', value: 'inactive' }
    ];
    const [selectedStatus, setSelectedStatus] = useState('all');
    const [isFileAttached, setIsFileAttached] = useState(false);
    const {
        subscriptionList: data,
        showExportModal,
        showSubscriptionModal,
        currentSubscription,
        refreshList
    } = useSelector(state => state.subscription);

    useEffect(() => {
        dispatch(fetchSubscriptionList(selectedStatus));
    }, []);

    useEffect(() => {
        if (refreshList) {
            dispatch(fetchSubscriptionList(selectedStatus));
        }
    }, [refreshList]);

    const linkList = [];
    const handleExportCloseModal = () => {
        dispatch(setExportModalStatus(false));
        dispatch(setCurrentSubscription(null));
    };
    const handleCloseModal = () => {
        dispatch(setSubscriptionModalStatus(false));
        dispatch(setCurrentSubscription(null));
    };

    const hancleSubscriptionStatusChange = (event) => {
        setSelectedStatus(event.target.value);
        dispatch(fetchSubscriptionList(event.target.value));
    };
    const handleUploadNewFile = (file) => {
        const formData = new FormData();
        formData.append('file', file);
        dispatch(setUploadProgress({ value: true, data: formData }));
    };

    const handleExportClick = () => {
        dispatch(setExportModalStatus(true));
    };

    const handleRemoveFile = () => {
        setIsFileAttached(false);
    };
    return (
        <div className="subscriptionContainer">
            <div className="breadcrumbContainer">
                <div className="breadcrumb">
                    <Breadcrumb
                        linkList={linkList}
                        currentLinkName="Subscription"
                    />
                </div>
                <div className="buttonContainer">
                    <UploadButton
                        label="Import File"
                        handleClick={handleUploadNewFile}
                        handleCloseAction={handleRemoveFile}
                        showFileNamePill={isFileAttached}
                    />
                    <Button
                        label="Export File"
                        customStyle="exportButton"
                        className="exportButton"
                        isButtonPrimary={true}
                        ariaLabel="Export"
                        handleOnClick={handleExportClick}
                    />
                </div>
            </div>
            <Table
                tableColumns={columnsHeaders}
                rowsData={data}
                isActionDropdownRequired={true}
                moreOptions={subscriptionStatusList}
                selectedOption={selectedStatus}
                handleOptionSelect={hancleSubscriptionStatusChange}
                isActionButtonRequired={false}
                isSearchRequired={false}
                actions={Actions}
            />
            {showSubscriptionModal && (
                <Sheet
                    header="View Subscription"
                    onCloseClick={() => handleCloseModal()}
                >
                    <SubscriptionDetails rowData={currentSubscription} />
                </Sheet>
            )}
            {showExportModal && (
                <Sheet
                    header="View Subscription"
                    onCloseClick={() => handleExportCloseModal()}
                >
                    <ExportSubscriptionModal />
                </Sheet>
            )}
        </div>
    );
};

export default Subscription;
