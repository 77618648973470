import keys from './config/keys';

export const baseUrl = keys.apiBaseUrl;
export const ACCESS_TOKEN = 'access_token';
export const ID_TOKEN = 'id_token';
export const EXPIRES = 'expires';
export const AUTH = 'auth';
export const AUTH_CODE = 'auth_code';
export const REFRESH_TOKEN = 'refresh_token';
export const OAUTH2_REDIRECT_URI = keys.redirectUri;
export const AWS_BASE_URL = keys.authBaseUrl;
export const REACT_APP_CLIENT_ID = 't9cq5qsmsg4dtfofiigvl6j02';
export const AWS_AUTH_URL = `${keys.authBaseUrl}/login?client_id=${keys.clientId}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid&redirect_uri=${keys.redirectUri}`;
