import { toast } from 'react-toastify';
import success from './assets/icons/success.svg';
import error from './assets/icons/error.svg';
import warning from './assets/icons/warning.svg';
import info from './assets/icons/info.svg';

export const showToast = (type, message, ...rest) => {
    switch (type) {
        case 'success':
            toast.success(message, {
                icon: () => <img src={success}/>,
                ...rest[0]
            });
            break;
        case 'error':
            toast.error(message, {
                icon: () => <img src={error}/>
            });
            break;
        case 'warning':
            toast.warning(message, {
                icon: () => <img src={warning}/>
            });
            break;
        case 'info':
            toast.info(message, {
                icon: () => <img src={info}/>
            });
            break;
        default:
            toast(message);
    }
};

export const getUrlParameter = (name, search) => {
    let n = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp(n + '=([^&#]*)');

    var results = regex.exec(search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export const downloadFile = (data, fileName) => {
    const myBlobPythonFile = new Blob([data]);
    const url = window.URL.createObjectURL(myBlobPythonFile);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
};
