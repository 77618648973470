import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Logo from '../../assets/icons/Logo.svg';
import avatar from '../../assets/icons/avatar.svg';
import * as _ from 'lodash';

import './style.css';

const NavigationBar = ({ authenticated, handleLogout }) => {
    const { userInfo } = useSelector(state => state.activeView);
    const initials = !_.isEmpty(userInfo) && userInfo.name.split(' ').reduce((item, curr) => item[0] + curr[0]).toUpperCase();
    return <nav className="neo-navbar">
        <div className="neo-nav--left">
            <Link
                to="/"
                className="neo-navbar__brand"
                title="Neo Framework"
            >
                <img alt="avayaLogo" src={Logo} />
            </Link>
            <h4>Avaya Subscriptions Control</h4>
        </div>
        <div className="neo-nav">
            {authenticated && <div className="neo-dropdown neo-dropdown--onhover neo-dropdown--left">
                <div className="neo-nav-status" style={{ justifyContent: 'flex-end' }} tabIndex="0">
                    <div className="neo-nav-status-info">
                        <p className="userName neo-text-capitalized">{userInfo.name}</p>
                    </div>
                    <figure
                        className="neo-avatar neo-avatar--navbar-nested neo-avatar--medium neo-dropdown__link-header"
                        data-initials={initials}
                        title={userInfo.username}
                    >
                        {!userInfo.username && <img className="neo-img" src={avatar} alt="Small avatar" />}
                    </figure>
                </div>
                <div className="neo-dropdown__content" style={{ right: 8 }}>
                    <a className="neo-dropdown__link userName">
                        <figure className="neo-avatar neo-avatar--small" data-initials={initials}>
                            {!userInfo.username && <img className="neo-img" src={avatar} alt="Small avatar" />}
                        </figure>
                        <span>
                            <p className="neo-text-capitalized">{`${userInfo.username}`}</p>
                            <p>{`${userInfo.email}`}</p>
                        </span>
                    </a>
                    <hr className="neo-dropdown__separator" />
                    <a id="logout" className="neo-dropdown__link" onClick={handleLogout}>
                        Logout
                    </a>
                </div>
            </div>}
        </div>
    </nav>;
};

NavigationBar.propTypes = {
    authenticated: PropTypes.bool,
    handleLogout: PropTypes.func
};

export default NavigationBar;
