import { SET_DELETE_MODAL_STATUS,
    SET_SUBSCRIPTION_LIST,
    SET_SUBSCRIPTION_MODAL_STATUS,
    REFRESH_LIST,
    SET_EXPORT_MODAL_STATUS,
    SET_SUBSCRIPTION_PROPERTIES,
    SET_CURRENT_SUBSCRIPTION } from './actionTypes';

const initialState = {
    showDeleteModal: false,
    showExportModal: false,
    showSubscriptionModal: false,
    currentSubscription: null,
    subscriptionList: [],
    refreshList: false,
    subscriptionProperties: []
};

export default (
    state = initialState,
    action
) => {
    switch (action.type) {
        case SET_DELETE_MODAL_STATUS:
            return {
                ...state,
                showDeleteModal: action.payload
            };
        case SET_SUBSCRIPTION_MODAL_STATUS:
            return {
                ...state,
                showSubscriptionModal: action.payload
            };
        case SET_CURRENT_SUBSCRIPTION:
            return {
                ...state,
                currentSubscription: action.payload
            };
        case SET_SUBSCRIPTION_LIST:
            return {
                ...state,
                subscriptionList: action.payload
            };
        case REFRESH_LIST:
            return {
                ...state,
                refreshList: action.payload
            };
        case SET_SUBSCRIPTION_PROPERTIES:
            return {
                ...state,
                subscriptionProperties: action.payload
            };
        case SET_EXPORT_MODAL_STATUS:
            return {
                ...state,
                showExportModal: action.payload
            };
        default:
            return {
                ...state
            };
    }
};
