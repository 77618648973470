import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../CommonComponents/Button';
import { fetchSubscriptionProperties, exportSubscriptions } from './action';

const ExportSubscriptionModal = () => {
    const dispatch = useDispatch();
    const { subscriptionProperties } = useSelector(state => state.subscription);
    useEffect(() => dispatch(fetchSubscriptionProperties(true)), []);
    const [subscriptionPropertiesList, setSubscriptionPropertiesList] = useState([]);
    const [exportDisabled, setExportDisabled] = useState([]);
    useEffect(() => {
        const tempSubscriptionProperties = subscriptionProperties.map(propertyTemp => ({
            propertyName: propertyTemp.propertyName, checked: false, propertyDescription: propertyTemp.propertyDescription
        }));
        setSubscriptionPropertiesList(tempSubscriptionProperties);
    }, [subscriptionProperties]);
    const handleCheckboxClick = (propertyTemp, index) => {
        const tempProperty = [...subscriptionPropertiesList];
        tempProperty[index].checked = !propertyTemp.checked;
        setSubscriptionPropertiesList(tempProperty);
        let isExportDisabled = true;
        subscriptionPropertiesList.forEach((property) => {
            if (property.checked) {
                isExportDisabled = false;
            }
        });
        setExportDisabled(isExportDisabled);
    };
    const handleExportClick = () => {
        const checkedList = [];
        subscriptionPropertiesList.forEach((property) => {
            if (property.checked) {
                checkedList.push(property.propertyName);
            }
        });
        dispatch(exportSubscriptions(checkedList.toString()));
    };

    return (
        <div>
            {
                subscriptionPropertiesList.map((property, index) => (
                    <>
                        <form className="neo-form">
                            <div className="neo-form-control neo-form-control--checkboxes">
                                <div className="neo-input-group">
                                    <input
                                        key={`checkbox-${index}`}
                                        className="neo-check"
                                        type="checkbox"
                                        id={`checkbox-${index}`}
                                        role="checkbox"
                                        aria-checked="false"
                                        value={property.propertyName}
                                        onChange={() => handleCheckboxClick(property, index)} />
                                    <label htmlFor={`checkbox-${index}`}>{property.propertyDescription}</label>
                                </div>
                            </div>
                        </form>
                    </>
                ))
            }
            <Button
                label="Export"
                customStyle="marginTop floatRight"
                isButtonPrimary={true}
                ariaLabel="Export"
                handleOnClick={handleExportClick}
                disabled={exportDisabled}
            />
        </div>
    );
};

export default ExportSubscriptionModal;
