import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Label from '../../CommonComponents/Label';
import LabelInput from '../../CommonComponents/LabelInput';
import Button from '../../CommonComponents/Button';
import Table from '../../CommonComponents/Table';
import { updateCustomerDetails } from './action';

const CustomerDetails = ({ customer }) => {
    const dispatch = useDispatch();
    const [region, setProjectCall] = useState(customer?.region ? customer?.region : '');
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);
    const columns = [
        {
            key: 'contract',
            header: 'Contract',
            show: true,
            hasLink: false
        },
        {
            key: 'product',
            header: 'Product',
            show: true,
            hasLink: false
        },
        {
            key: 'startDate',
            header: 'Start Date',
            show: true,
            hasLink: false
        },
        {
            key: 'endDate',
            header: 'End Date',
            show: true,
            hasLink: false
        }
    ];
    const handleProjectCallSave = () => {
        dispatch(updateCustomerDetails({ ...customer, region }));
        setUpdateButtonDisabled(true);
    };

    return (<div className="neo-form">
                <div className="neo-form-control">
                    <div className="neo-input-group bottomMargin">
                    <Label
                        label="ID"
                        value={customer.id}
                        id="customerId"
                        labelStyle="lableStyleTwo"
                        customClasses="nameLabelStyle"
                    />
                    </div>
                </div>
                <div className="neo-form-control">
                    <div className="neo-input-group bottomMargin">
                    <Label
                        label="Name"
                        value={customer.name}
                        id="customerName"
                        labelStyle="lableStyleTwo"
                        customClasses="nameLabelStyle"
                    />
                    </div>
                </div>
                <div className="neo-form-control">
                    <div className="neo-input-group bottomMargin">
                    <Label
                        label="Alternate Name"
                        value={customer.alternativeName}
                        id="contactsalternateName"
                        labelStyle="lableStyleTwo"
                        customClasses="nameLabelStyle"
                    />
                    </div>
                </div>
                <div className="neo-form-control">
                    <div className="neo-input-group bottomMargin">
                    <Label
                        label="Country"
                        value={customer.location}
                        id="contactsEmail"
                        labelStyle="lableStyleTwo"
                        customClasses="nameLabelStyle"
                    />
                    </div>
                </div>
                <div className="neo-form-control">
                    <div className="neo-input-group bottomMargin">
                      <LabelInput
                          inputValue={region}
                          label="Region"
                          placeholder="Enter Region"
                          onInputChange={(e) => {
                            setProjectCall(e.target.value);
                            setUpdateButtonDisabled(false);
                          }}
                          required={false}
                      />
                    </div>
                  </div>
                  <div className="neo-form-control textAlignRight">
                    <div className="neo-input-group--inline bottomMargin">
                      <Button
                            label="Update"
                            customStyle=""
                            isButtonPrimary={true}
                            ariaLabel="Save"
                            handleOnClick={handleProjectCallSave}
                            disabled={updateButtonDisabled}
                        />
                    </div>
                </div>
                <div className="neo-input-group bottomMargin">
                    <Table
                        tableColumns={columns}
                        rowsData={customer.subscriptions}
                        isPaginationRequired={false}
                        isSearchRequired={false}
                        isActionButtonRequired={false}
                    />
                </div>
    </div>);
};

CustomerDetails.propTypes = {
    customer: PropTypes.object
};

export default CustomerDetails;
