import {
    SET_SPINNER_STATUS,
    IS_FETCHING_USER_INFO,
    SET_USER_INFO,
    IS_AUTHENTICATED,
    SET_FETCH_USER_ERROR,
    LOGOUT_USER
} from './actionTypes';

export const setSpinnerStatus = payload => ({
    type: SET_SPINNER_STATUS,
    payload
});

export const fetchUserInfo = payload => ({
    type: IS_FETCHING_USER_INFO,
    payload
});

export const setUserInfo = payload => ({
    type: SET_USER_INFO,
    payload
});

export const setUserInfoError = payload => ({
    type: SET_FETCH_USER_ERROR,
    payload
});

export const setIsAutenticated = payload => ({
    type: IS_AUTHENTICATED,
    payload
});

export const logoutUser = payload => ({
    type: LOGOUT_USER,
    payload
});
