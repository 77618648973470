import { call, all, takeLatest, put } from '@redux-saga/core/effects';
import myAxios from '../../authAxios';
import { baseUrl } from '../../constants';
import { IS_FETCHING_SUBSCRIPTION_LIST, IS_FETCHING_SUBSCRIPTION_PROPERTIES, EXPORT_SUBSCRIPTIONS, FETCH_SUBSCRIPTION_DETAIL, IS_UPLOADING_IN_PROGRESS, UPDATE_SUBSCRIPTION } from './actionTypes';
import { setSubscriptionList, setSubscriptionProperties, refreshList, setCurrentSubscription } from './action';
import { setSpinnerStatus } from '../../Containers/ActiveView/action';
import { showToast, downloadFile } from '../../utils';

const fetchSubscriptionList = (status, within) => myAxios.get(`${baseUrl}/subscriptions?status=${status}&within=${within}`);
const fetchSubscriptionDetail = subscriptionId => myAxios.get(`${baseUrl}/subscriptions/${subscriptionId}`);

const updateSubscription = payload => myAxios.put(`${baseUrl}/subscriptions`, payload);

const handleFetchSubscriptionList = function* (args) {
    try {
        yield put(setSpinnerStatus(true));
        let within = 0;
        if (args.payload === 'expire') {
            within = 90;
        }
        const response = yield call(fetchSubscriptionList, args.payload, within);
        const subscriptionList = response.data.map((subscriptionItem) => {
            let subscriptionTemp = subscriptionItem;
            subscriptionTemp.startDate = `${subscriptionItem.startDate}`;
            subscriptionTemp.endDate = `${subscriptionItem.endDate}`;
            return subscriptionTemp;
        });
        yield put(setSubscriptionList(subscriptionList));
        yield put(setSpinnerStatus(false));
    } catch (error) {
        yield call(showToast, 'error', error.response ? error.response.data.message : error.message);
        yield put(setSpinnerStatus(false));
    }
};

const handleFetchSubscriptionDetail = function* (args) {
    try {
        yield put(setSpinnerStatus(true));
        const response = yield call(fetchSubscriptionDetail, args.payload);
        yield put(setCurrentSubscription(response.data));
        yield put(setSpinnerStatus(false));
    } catch (error) {
        yield call(showToast, 'error', error.response ? error.response.data.message : error.message);
        yield put(setSpinnerStatus(false));
    }
};

const hendleUpdateSubscription = function* (args) {
    try {
        yield put(setSpinnerStatus(true));
        yield put(refreshList(false));
        yield call(updateSubscription, args.payload);
        yield put(refreshList(true));
        yield call(showToast, 'success', 'Subscription Updated Successfully.');
        yield put(setSpinnerStatus(false));
    } catch (error) {
        yield call(showToast, 'error', error.response ? error.response.data.message : error.message);
        yield put(setSpinnerStatus(false));
    }
};

const handleUploadFile = function* (action) {
    try {
        yield put(setSpinnerStatus(true));
        yield put(refreshList(false));
        yield call(() => myAxios.post(`${baseUrl}/subscriptions/upload`, action.payload.data));
        yield put(refreshList(true));
        yield call(showToast, 'success', 'Subscriptions List Updated Successfully.');
        yield put(setSpinnerStatus(false));
    } catch (error) {
        yield call(showToast, 'error', error.response ? error.response.data.message : error.message);
        yield put(setSpinnerStatus(false));
    }
};

const handleExportSubscriptions = function* (action) {
    try {
        yield put(setSpinnerStatus(true));
        const data = yield call(() => myAxios.get(`${baseUrl}/subscriptions/export?columns=${action.payload}`, {
            headers: {
                'content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            responseType: 'arraybuffer'
        },));
        const dat = data.data;
        yield call(downloadFile, dat, 'subscriptionList.xlsx');
        yield put(setSpinnerStatus(false));
    } catch (error) {
        yield call(showToast, 'error', error.response ? error.response.data.message : error.message);
        yield put(setSpinnerStatus(false));
    }
};

const handleFetchSubscriptionProperties = function* () {
    try {
        yield put(setSpinnerStatus(true));
        const response = yield call(() => myAxios.get(`${baseUrl}/subscriptions/properties`));
        yield put(setSubscriptionProperties(response.data));
        yield put(setSpinnerStatus(false));
    } catch (error) {
        yield call(showToast, 'error', error.response ? error.response.data.message : error.message);
        yield put(setSpinnerStatus(false));
    }
};

const subscriptionSaga = function* (...args) {
    try {
        yield all([
            takeLatest(IS_FETCHING_SUBSCRIPTION_LIST, handleFetchSubscriptionList, ...args),
            takeLatest(IS_UPLOADING_IN_PROGRESS, handleUploadFile, ...args),
            takeLatest(UPDATE_SUBSCRIPTION, hendleUpdateSubscription, ...args),
            takeLatest(IS_FETCHING_SUBSCRIPTION_PROPERTIES, handleFetchSubscriptionProperties, ...args),
            takeLatest(FETCH_SUBSCRIPTION_DETAIL, handleFetchSubscriptionDetail, ...args),
            takeLatest(EXPORT_SUBSCRIPTIONS, handleExportSubscriptions, ...args)
        ]);
    } catch (error) {
        const errorMessage = error.response ? error.response.data.data : error.message;
        yield call(showToast, 'error', errorMessage);
    }
};

export default subscriptionSaga;
