import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import Dashboard from './Containers/Dashboard';

import './App.css';

const App = () => (
        <div className="App">
            <Dashboard />
        </div>
);

App.propTypes = {
    location: PropTypes.object,
    hash: PropTypes.string
};

export default withRouter(App);
