import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import Selectbox from '../CommonComponents/Selectbox/index.js';
const PaginationBar = (props) => {
    const {
        pageNumber,
        pageSize,
        pageSizeList,
        totalCount,
        pageSizeChanged,
        setCurrentPageNumber
    } = props;
    const pageRange = Math.ceil(totalCount / pageSize) + 1;
    const [rangeView, setRangeView] = useState([]);

    useEffect(() => {
        if (pageRange > 3) {
            setRangeView([
                1, 2, 3
            ]);
        } else {
            const rangeArr = _.range(1, pageRange);
            setRangeView(rangeArr);
        }
    }, [pageSize, pageRange]);

    const handleMoveForward = () => {
        if (rangeView[2] === pageNumber) {
            let tempRange = rangeView.slice();
            tempRange.shift();
            tempRange.push(rangeView[2] + 1);
            setRangeView(tempRange);
        }
        setCurrentPageNumber(pageNumber + 1);
    };
    const handleMoveBackward = () => {
        if (rangeView[0] === pageNumber) {
            let tempRange = rangeView.slice();
            tempRange.pop();
            tempRange.unshift(rangeView[0] - 1);
            setRangeView(tempRange);
        }
        setCurrentPageNumber(pageNumber - 1);
    };
    return (
        <div className="neo-pagination__row">
            <div>
                <p>
                    {(pageNumber - 1) * pageSize + 1} - {pageNumber * pageSize}
                </p>
            </div>
            <nav
                className="neo-pagination"
                role="navigation"
                aria-label="pagination"
            >
                <button
                    type="button"
                    className={`neo-btn-square neo-pagination__arrow-btn neo-icon-arrow-left`}
                    role="button"
                    disabled={pageNumber === 1}
                    onClick={handleMoveBackward}
                />
                <ul className="neo-pagination__list">
                {pageRange > 3 && rangeView[2] - 2 !== 1 && <li>
                    <span className="neo-pagination__dots">&hellip;</span>
                </li>}
                    {rangeView.map(pageNumberItem => (
                            <li key={pageNumberItem}>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setCurrentPageNumber(pageNumberItem);
                                    }}
                                    className={`neo-btn-square ${
                                        pageNumberItem === pageNumber
                                            ? 'neo-btn-square-secondary neo-btn-square-secondary--info'
                                            : 'neo-btn-square-tertiary neo-btn-square-tertiary--info'
                                    }`}
                                    role="button"
                                >
                                    {pageNumberItem}
                                </button>
                            </li>
                        ))}
                    {pageRange > 3 && rangeView[0] + 2 !== pageRange - 1 && <li>
                        <span className="neo-pagination__dots">&hellip;</span>
                    </li>}
                </ul>
                <button
                    type="button"
                    className={`neo-btn-square neo-pagination__arrow-btn neo-icon-arrow-right`}
                    role="button"
                    disabled={pageNumber === pageRange - 1}
                    onClick={handleMoveForward}
                />
            </nav>
            <div>
                <div className="neo-form neo-form--inline">
                    <span>Show:&nbsp;</span>
                    <div id="pageSize" className="neo-form-control">
                        <Selectbox
                            options={pageSizeList}
                            selected={pageSize}
                            onSelect={pageSizeChanged}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

PaginationBar.propTypes = {
    pageNumber: PropTypes.number,
    pageSize: PropTypes.number,
    pageSizeList: PropTypes.array,
    totalCount: PropTypes.number,
    pageSizeChanged: PropTypes.func,
    setCurrentPageNumber: PropTypes.func
};

export default PaginationBar;
